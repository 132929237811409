//import Banner from './Banner';
import Footer from './Footer';
import Header from './Header'
import { Outlet } from "react-router-dom";

export default function Layout({ admin }) {
    return (
        <>
            {/* <Banner /> */}
            <Header admin={admin} />
            <Outlet />
            <Footer />
        </>
    )
}