import { createContext, useContext, useMemo, useState } from "react";

const SearchContext = createContext()

export const SearchProvider = ({ children }) => {

    const [search, setSearch] = useState(false)
    const value = useMemo(() => ({ search, setSearch }), [search])

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}

export const useSearch = () => {
    return useContext(SearchContext)
}