import { BiLogoInstagram, BiLogoFacebookSquare, BiLogoWhatsapp, BiLogoTwitter } from "react-icons/bi";
import { Container, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function Footer() {

    const social = [
        { link: "https://www.instagram.com/motormax.com.ar", icon: <BiLogoInstagram className="icon fs-5" /> },
        { link: "https://twitter.com/motormax_com_ar", icon: <BiLogoTwitter className="icon fs-5" /> },
        { link: "https://www.facebook.com/motormax.com.ar", icon: <BiLogoFacebookSquare className="icon fs-5" /> },
        { link: process.env.REACT_APP_WA_LINK, icon: <BiLogoWhatsapp className="icon fs-5" /> }
    ]

    return <footer id="footer">
        <Container className="d-md-flex pt-4 pb-3">
            <div className="me-md-auto text-center text-md-start d-flex align-items-center justify-content-center">
                <Image alt="Motormax" src="https://storage.googleapis.com/static_mmxfront/logowk.webp" height="30" />

            </div>
            <div className="text-center pt-3 pt-md-0 small links d-flex align-items-center justify-content-center">
                <LinkContainer to={"/privacy"}>
                    <a href="/privacy">Política de privacidad</a>
                </LinkContainer>
                <span>&bull;</span>
                <LinkContainer to={"/tos"}>
                    <a href="/tos">Términos y condiciones</a>
                </LinkContainer>
            </div>
            <div className="text-md-end pt-md-0 d-flex align-items-center justify-content-center">
                {social.map((elm, i) => <a className="text-docoration-none d-inline-block text-center px-2 mt-3 mt-md-1" key={i} href={elm.link}>{elm.icon}</a>)}
            </div>
        </Container>
        <Container className="mx-auto pb-4 small text-center text-md-start">
            <small className="d-inline-block ms-1" style={{ color: "gray" }}>
                PIMENTO SRL CUIT 30714273090<br />Esteban Echeverría 3750, Vicente Lopez<br />Provincia de Buenos Aires
            </small>
        </Container>
    </footer>
}
