import { createContext, useContext, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCookie from 'react-use-cookie';

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

    const { search } = useLocation()
    const [user, setUser] = useCookie('token')
    const [utms, setUtms] = useCookie('utms')
    const navigate = useNavigate()

    const login = async data => {
        const { rol } = data
        if (rol === 1) {
            setUser(data.token, {
                days: 7,
                SameSite: 'Strict',
                Secure: true,
            })
        }
        navigate("/buy-car")
    }

    useEffect(() => {
        if (!!search) {
            setUtms(search, {
                SameSite: 'Strict',
                Secure: true,
            })
        }
    }, [search])

    const logout = () => {
        setUser("");
        navigate("/login", { replace: true })
    }

    const signed = !!user.length

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            signed
        }),
        [user]
    )

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
