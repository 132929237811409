import { Container, Navbar, Image, Nav, Offcanvas, CloseButton, Button } from 'react-bootstrap';
import { MdOutlineMonetizationOn, MdHome, MdOutlineVerifiedUser, MdPeopleAlt, MdEmojiTransportation, MdOutlineInfo } from 'react-icons/md';
import { LinkContainer } from 'react-router-bootstrap';
import { useEffect, useState } from 'react';
import { BiLogoInstagram, BiLogoFacebookSquare, BiLogoWhatsapp, BiLogoTwitter } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useSearch } from '../hooks/useSearch';
import HeaderForm from './HeaderForm';
import { BsSearch, BsList, BsPeople, BsQuestionCircle } from "react-icons/bs";



export default function Header({ admin }) {

  const { search } = useSearch()
  const { logout, signed } = useAuth()
  const location = useLocation()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (location) {
      handleClose()
    }
  }, [location])

  return (
    <>
      <Navbar expand="lg" className="bg-white border-bottom border-dark-subtle shadow-sm py-1" sticky="top">
        <Container className='px-3 py-0'>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                src="https://storage.googleapis.com/static_mmxfront/logo.webp"
                width="auto"
                height="30"
                className="align-top"
                alt="Mortormax"
              />
            </Navbar.Brand>
          </LinkContainer>
          <div className='d-none d-sm-block'>
            <Nav className="d-flex flex-row">
              {!signed && <>
                {search && <Nav.Item className='mx-2'>
                  <LinkContainer to={{ pathname: "/buy-car", search: location.search, hash: "search" }}>
                    <Nav.Link className='fw-semibold text-dark'><small><BsSearch className='icon me-1' />Buscar</small></Nav.Link>
                  </LinkContainer>
                </Nav.Item>}
                <Nav.Item className='mx-2'>
                  <LinkContainer to="/about">
                    <Nav.Link className='fw-semibold text-dark'><small><BsQuestionCircle className='icon me-1' />Quiénes somos</small></Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item className="mx-2 me-0">
                  <LinkContainer to="/team">
                    <Nav.Link className='fw-semibold text-dark'><small><BsPeople className='icon me-1' />Equipo</small></Nav.Link>
                  </LinkContainer>
                </Nav.Item></>}

              {(!signed && admin) && <Nav.Item className="mx-2">
                <LinkContainer to="/login">
                  <Nav.Link className='fw-semibold text-dark'><small>Ingresar</small></Nav.Link>
                </LinkContainer>
              </Nav.Item>}

              {signed && <>
                <HeaderForm />

                <Nav.Item className='mx-2'>
                  <LinkContainer to="/admin/car/create">
                    <Nav.Link className='fw-semibold text-dark'><small>Nueva publicación</small></Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item className='mx-2 me-0'>
                  <Nav.Link onClick={logout} className='fw-semibold text-dark'><small>Salir</small></Nav.Link>
                </Nav.Item></>}
            </Nav>
          </div>

          <div className='d-block d-sm-none'>
            <Nav className="d-flex flex-row">
              {search && <Nav.Item>
                <LinkContainer to={{ pathname: "/buy-car", search: location.search, hash: "search" }}>
                  <Nav.Link role='button' className='me-4 d-flex align-items-center'>
                    <Button variant='link' className='text-body-tertiary p-0'>
                      <BsSearch className='fs-5' />
                    </Button>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>}
              <Nav.Item>
                <Nav.Link onClick={handleShow} role='button' className='d-flex align-items-center'>
                  <Button variant='link' className='text-body-tertiary p-0'>
                    <BsList className='fs-1' />
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement='end' className="text-white-50" style={{ background: "#111" }}>
        <Offcanvas.Header className='px-3 pb-1' closeButton={false}>
          <Offcanvas.Title>
            <Image src="https://storage.googleapis.com/static_mmxfront/logowk.webp" height="34" width="auto" alt='Motormax' />
          </Offcanvas.Title>
          <CloseButton onClick={e => setShow(false)} variant='white' />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav flex-row flex-wrap ps-3">
            <NavItem size="col-12" to="/sell-car" text="Vendé tu auto" icon={<MdOutlineMonetizationOn className='icon me-2' />} />
            <NavItem size="col-12" to="/buy-car" text="Comprá tu próximo auto" icon={<MdOutlineVerifiedUser className='icon me-2' />} />
            <NavItem size="col-12" to="https://wa.me/+5491131814227?text=Hola Motormax! Estoy interesado en cotizar una flota" text="Compramos flotas corporativas" icon={<MdEmojiTransportation className='icon me-2' />} />
          </ul>
          <hr className="text-white-75 my-4"></hr>
          <ul className="navbar-nav flex-row flex-wrap ps-3">
            <NavItem to="/" text="Home" icon={<MdHome className='icon me-2' />} />
            <NavItem to="/about" text="Quiénes somos" icon={<MdOutlineInfo className='icon me-2' />} />
            <NavItem to="/team" text="Equipo" icon={<MdPeopleAlt className='icon me-2' />} />
          </ul>
          <hr className="text-white-75 my-4"></hr>
          <ul className="navbar-nav flex-row flex-wrap ps-3">
            <NavItem size="col-12" to="/privacy" text="Política de privacidad" icon={null} />
            <NavItem size="col-12" to="/tos" text="Términos y condiciones" icon={null} />
          </ul>
          <hr className="text-white-75 my-4"></hr>
          <ul className="navbar-nav flex-row flex-wrap ps-3">
            <NavItem color="#C13584" to="https://instagram.com/motormax.com.ar" text="Instagram" icon={<BiLogoInstagram className='icon me-2' />} />
            <NavItem color="#25D366" to={process.env.REACT_APP_WA_LINK} text="WhatsApp" icon={<BiLogoWhatsapp className='icon me-2' />} />
            <NavItem color="#4267B2" to="https://facebook.com/motormax.com.ar" text="Facebook" icon={<BiLogoFacebookSquare className='icon me-2' />} />
            <NavItem color="#1DA1F2" to="https://twitter.com/motormax_com_ar" text="Twitter" icon={<BiLogoTwitter className='icon me-2' />} />
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}


/* const DropdownItem = ({ mode, setTheme, active }) => {

  const txt = mode === "light" ? "Claro" : "Oscuro"
  const icon = mode === "light" ? <MdOutlineLightMode className='icon' /> : <MdOutlineDarkMode className='icon' />

  return (
    <Dropdown.Item onClick={e => setTheme(mode)} active={null}>
      <div className='d-flex align-items-center'>
        {icon}<span className='ms-2 me-auto'>{txt}</span>{active && <MdOutlineCheck className='icon' />}
      </div>
    </Dropdown.Item>
  )
} */

const NavItem = (props) => {
  const absolute = props.to.includes("http")
  const link = <Nav.Link style={{ color: props.color }} href={props.to}><small>{props.icon}{props.text}</small></Nav.Link>
  return (
    <Nav.Item as="li" className={props?.size || "col-6"}>
      {absolute ? link : <LinkContainer activeClassName='text-white fw-semibold' to={props.to}>{link}</LinkContainer>}
    </Nav.Item>
  )
}


