import { useState } from "react"
import { MdOutlineSearch } from "react-icons/md"
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom"
import { IoCloseCircle } from "react-icons/io5";


const plateRgx = /^[A-Z]{2}\d{3}[A-Z]{2}$|^[A-Z]{3}\d{3}$/;

export default function HeaderForm() {

    const [value, setValue] = useState("")
    const navigate = useNavigate()

    const handleSubmit = e => {
        e.preventDefault()

        if (plateRgx.test(value)) {
            navigate(`/buy-car?plate=${value}`)
        }
    }

    return (
        <div className='search-form position-absolute start-50 top-50 translate-middle'>
            <form onSubmit={e => handleSubmit(e)} className="d-flex align-items-center" role="search">
                <div className='position-relative w-100'>
                    <MdOutlineSearch className='ms-2 icon text-black-50 position-absolute top-50 start-0 translate-middle-y' />
                    <input value={value || ""} required minLength="6" maxLength="7" onChange={e => setValue(e.target.value.trim().toUpperCase())} spellCheck={false} className="form-control form-control-sm text-center rounded-5" type="text" placeholder="Buscar dominio" />
                    {value.length > 0 && <LinkContainer onClick={e => setValue("")} to='/buy-car'>
                        <IoCloseCircle role="button" className='me-2 icon text-black-50 position-absolute top-50 end-0 translate-middle-y' />
                    </LinkContainer>}
                </div>
            </form>
        </div>
    )
}
